import React, { Component } from "react";
import {
  Button,
  Col,
  Collapse,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  Row,
  Table,
} from "reactstrap";
import { SERVER_URL } from "../constants/variables";
import logo from "../assets/images/grameen_logo_trans.png";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";

class RolesComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {},
      roles: [],
      addModalOpen: false,
      roleName: "",
    };

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  toggleAddModal = () => {
    this.setState({
      roleName: "",
      addModalOpen: !this.state.addModalOpen,
    });
  };

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    console.log(name, ",", value);

    this.setState({
      roleName: value,
    });
  }

  onAddRoleSubmit = () => {
    var role = this.state.roleName;
    if (role === "") {
      toast("Enter all fields");
    } else {
      toast.loading("Adding role", {
        duration: 2000,
      });
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        name: role,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(SERVER_URL + "/roles/addRole", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          toast.success("Role added successfully");
          this.getRoles();
          this.toggleAddModal();
        })
        .catch((error) => console.log("error", error));
    }
  };

  getRoles = () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(SERVER_URL + "/roles/getRoles", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          this.setState({ roles: result.roles });
        }
      })
      .catch((error) => console.log("error", error));
  };

  deleteRole = (id) => {
    toast.loading("", {
      duration: 2000,
    });
    var myHeaders = new Headers();
    myHeaders.append("role", id);

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(SERVER_URL + "/roles/deleteRole", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        toast.success("Role deleted successfully");
        this.getRoles();
      })
      .catch((error) => console.log("error", error));
  };

  componentDidMount() {
    if (localStorage.getItem("user") != null) {
      var user = localStorage.getItem("user");
      this.setState({
        user: user,
      });
      this.getRoles();
      // if (user.role === "SUPER_ADMIN") {
      //   this.setState({
      //     user: user,
      //   });
      // } else {
      //   window.location = "/login";
      // }
    }
  }
  render() {
    return (
      <React.Fragment>
        <Navbar color="light" light expand="md">
          <NavbarBrand href="/">
            <img
              src={logo}
              className="img-fluid"
              alt="logo"
              style={{ width: "340px", height: "120px" }}
            />
          </NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <Link className="Link" to="/home">
                  Dashboard 1
                </Link>
              </NavItem>
              <NavItem onClick={() => localStorage.removeItem("user")}>
                <Link className="Link" to="/login">
                  Logout
                </Link>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
        <Container fluid>
          <Row>
            <Col sm>
              <div>
                <h3>Roles</h3>
              </div>
            </Col>
            <Col sm>
              <div style={{ textAlign: "right" }}>
                <Button color="primary" onClick={this.toggleAddModal}>
                  Add Role
                </Button>
              </div>
            </Col>
          </Row>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#ID</th>
                <th>Role Name</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {this.state.roles.map((role) => (
                <tr>
                  <td>{role._id}</td>
                  <td>{role.name}</td>
                  <td onClick={() => this.deleteRole(role._id)}>Delete</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Container>

        {/* Add Modal */}
        <Modal isOpen={this.state.addModalOpen} toggle={this.toggleAddModal}>
          <ModalHeader toggle={this.toggleAddModal}>Add Role</ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label for="roleName">Role Name</Label>
                <Input
                  type="text"
                  id="roleName"
                  name="roleName"
                  placeholder="Enter Role Name"
                  onChange={this.handleInputChange}
                />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.onAddRoleSubmit}>
              Add Role
            </Button>{" "}
            <Button color="secondary" onClick={this.toggleAddModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default RolesComponent;
