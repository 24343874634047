import React, { Component } from 'react'
import { Row, Container, Col, Form, Label, Input, FormGroup, Button } from 'reactstrap';
import toast from 'react-hot-toast';
import { SERVER_URL } from '../constants/variables';
import logo from "../assets/images/grameen_logo_trans.png";

export default class LoginComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: ""
      
    }
    this.handleInputChange = this.handleInputChange.bind(this);

  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  onLoginSubmit = () => {
    var email = this.state.email;
    var password = this.state.password;
    if(email === "" || password === "") {
      toast("Enter all fields");
    } else {
      toast.loading("Checking credentials", {
        duration: 2000
      });
      var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
          "username": email,
          "password": password
        });

        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };

        fetch(SERVER_URL + "/users/login", requestOptions)
          .then(response => response.json())
          .then(result => {
            if(result.success) {
              toast.success("Login successful");
              localStorage.setItem("user", JSON.stringify(result));
              window.location = "/home";
            } else {
              toast.error("Invalid credentials");
            }
          })
          .catch(error => console.log('error', error));
    }
  }

  componentDidMount() {
    var user = localStorage.getItem("user");
    if(user) {
        toast("Already logged in");
        window.location = "/home";
    }
  }
  render() {
    return (
      <React.Fragment>
        <Container>
          <Row>
            <Col sm>

            </Col>
            <Col sm>
              <div>
                <div>
                  <img src={logo} className="img-fluid" alt="logo" />
                </div>
                <h4 style={{textAlign: "center"}}>Login</h4>
                <Form>
                <FormGroup>
                  <Label for="exampleEmail">
                    Email
                  </Label>
                  <Input
                    id="email"
                    name="email"
                    placeholder="Enter login email"
                    type="email"
                    onChange={this.handleInputChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="examplePassword">
                    Password
                  </Label>
                  <Input
                    id="password"
                    name="password"
                    placeholder="Enter password"
                    type="password"
                    onChange={this.handleInputChange}
                  />
                  </FormGroup>

                  <Button type="button" color='primary' onClick={() => this.onLoginSubmit()}>Login</Button>
                </Form>
              </div>
            </Col>
            <Col sm></Col>
          </Row>
        </Container>
      </React.Fragment>
    )
  }
}
