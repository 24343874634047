import { BrowserRouter, Routes, Route } from "react-router-dom";

// import logo from './logo.svg';
import "./App.css";
import LoginComponent from "./components/LoginComponent";
import { Toaster } from "react-hot-toast";
import HomeComponent from "./components/HomeComponent";
import RolesComponent from "./components/RolesComponent";
import TabsComponent from "./components/TabsComponent";
import ResetPasswordComponent from "./components/ResetPasswordComponent";
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route exact path="/">
            <Route index element={<LoginComponent />} />
            <Route
              path="/home"
              element={<HomeComponent dashboardNumber={33} />}
            />
            <Route path="/login" element={<LoginComponent />} />
            <Route path="/roles" element={<RolesComponent />} />
            <Route path="/tabs" element={<TabsComponent />} />
            <Route path="/resetPassword" element={<ResetPasswordComponent />} />
          </Route>
        </Routes>
      </BrowserRouter>
      <Toaster />
    </div>
  );
}

export default App;
