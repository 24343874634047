import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Col,
  Collapse,
  Container,
  Input,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  Row,
} from "reactstrap";
import { SERVER_URL } from "../constants/variables";
import logo from "../assets/images/grameen_logo_trans.png";
import toast from "react-hot-toast";

class ResetPasswordComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      user: {},
      tabs: [],
      iFrameUrl: "",
      idRoleMap: {},
      newPassword: "",
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  submitResetPassword = () => {
    var pwdRegex =
      /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()+=-\?;,./{}|\":<>\[\]\\\' ~_]).{8,}/;
    if (this.state.newPassword.trim() === "") {
      toast.error("Please enter password");
    } else if (!pwdRegex.test(this.state.newPassword)) {
      toast.error("Password should satisfy criteria");
    } else {
      toast.loading("Updating.. ", {
        duration: 2000,
      });
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        email: this.state.user.user.email,
        password: this.state.newPassword,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(SERVER_URL + "/users/resetpassword", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          toast.success("Password updated successfully");
          this.setState({
            newPassword: "",
          });
        })
        .catch((error) => console.log("error", error));
    }
  };

  getRoles = () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(SERVER_URL + "/roles/getRoles", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          if (result.roles) {
            var map = {};
            result.roles.forEach((item) => {
              map[item._id] = item.name;
            });
            console.log(map);
            this.setState({
              idRoleMap: map,
            });
          }
        }
      })
      .catch((error) => console.log("error", error));
  };
  getTabsByRole = (roleId) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      role: roleId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(SERVER_URL + "/tabs/getTabsByRole", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          tabs: result.tabs,
        });
        console.log("Tabs: ", result.tabs);
      })
      .catch((error) => console.log("error", error));
  };
  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    console.log(name, ",", value);

    this.setState({
      [name]: value,
    });
  }
  componentDidMount() {
    if (localStorage.getItem("user") != null) {
      var user = localStorage.getItem("user");
      this.setState({
        user: JSON.parse(user),
      });
      this.getRoles();
      this.getTabsByRole(JSON.parse(user).user.role);
    } else {
      window.location = "/login";
    }
  }
  render() {
    return (
      <React.Fragment>
        <Navbar color="light" light expand="md">
          <NavbarBrand href="/">
            <img
              src={logo}
              className="img-fluid"
              alt="logo"
              style={{ width: "340px", height: "120px" }}
            />
          </NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
              {this.state.tabs &&
                this.state.tabs.map((item) => (
                  <NavItem>
                    <Link
                      onClick={() => {
                        localStorage.setItem(
                          "dashboardNumber",
                          item.dashboardNumber
                        );
                        window.location = "/home";
                      }}
                      className="Link"
                      to="/home"
                    >
                      {item.name}
                    </Link>
                  </NavItem>
                ))}
              {this.state.user.user &&
                this.state.idRoleMap[this.state.user.user.role] ===
                  "SUPER_ADMIN" && (
                  <NavItem>
                    <Link className="Link" to="/tabs">
                      Tabs
                    </Link>
                  </NavItem>
                )}
              <NavItem>
                <Link className="Link" to="/resetPassword">
                  Reset Password
                </Link>
              </NavItem>
              <NavItem
                onClick={() => {
                  localStorage.removeItem("user");
                  localStorage.removeItem("dashboardNumber");
                }}
              >
                <Link className="Link" to="/login">
                  Logout
                </Link>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>

        <Container fluid>
          <h3>Reset Password</h3>
          <div>
            <Row>
              <Col>
                <div>
                  <p>Password should contain: </p>
                  <ul>
                    <li>One Uppercase</li>
                    <li>One lowercase</li>
                    <li>One digit</li>
                    <li>One special character</li>
                  </ul>
                  <Input
                    name="newPassword"
                    type="password"
                    width={"40px"}
                    value={this.state.newPassword}
                    onChange={this.handleInputChange}
                    placeholder="Enter new password"
                  />
                  <br />
                  <Button
                    color="primary"
                    onClick={() => this.submitResetPassword()}
                  >
                    Update
                  </Button>
                </div>
              </Col>
              <Col></Col>
            </Row>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}

export default ResetPasswordComponent;
