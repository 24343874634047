import React, { Component } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
} from "reactstrap";
import { Link } from "react-router-dom";
import { SERVER_URL } from "../constants/variables";
import logo from "../assets/images/grameen_logo_trans.png";

class HomeComponent extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
      user: {},
      tabs: [],
      iFrameUrl: "",
      dashboardNumber: this.props.dashboardNumber,
      idRoleMap: {},
    };
  }

  getRoles = () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(SERVER_URL + "/roles/getRoles", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          if (result.roles) {
            var map = {};
            result.roles.forEach((item) => {
              map[item._id] = item.name;
            });
            console.log(map);
            this.setState({
              idRoleMap: map,
            });
          }
        }
      })
      .catch((error) => console.log("error", error));
  };
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  getIframeUrl = (user) => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };
    var dashboardNumber = this.state.dashboardNumber;
    if (
      localStorage.getItem("dashboardNumber") !== null ||
      localStorage.getItem("dashboardNumber") !== undefined
    )
      dashboardNumber = localStorage.getItem("dashboardNumber");
    console.log(dashboardNumber);
    fetch(
      SERVER_URL + "/users/metabase/" + parseInt(dashboardNumber),
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (result.success) {
          this.setState({ iFrameUrl: result.url });
        }
      })
      .catch((error) => console.log("error", error));
  };

  componentDidMount() {
    var user = localStorage.getItem("user");
    if (user) {
      this.setState({ user: JSON.parse(user) });
      console.log("user: ", user);
      this.getRoles();
      this.getTabsByRole(JSON.parse(user).user.role);
      this.getIframeUrl(user);
    } else {
      window.location = "/login";
    }
  }

  getTabsByRole = (roleId) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      role: roleId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(SERVER_URL + "/tabs/getTabsByRole", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          tabs: result.tabs,
        });
        console.log("Tabs: ", result.tabs);
        if (result.tabs.length > 0 && !localStorage.getItem("dashboardNumber"))
          localStorage.setItem(
            "dashboardNumber",
            result.tabs[0].dashboardNumber
          );
        this.getIframeUrl(this.state.user);
      })
      .catch((error) => console.log("error", error));
  };
  render() {
    return (
      <React.Fragment>
        <Navbar color="light" light expand="md">
          <NavbarBrand href="/">
            <img
              src={logo}
              className="img-fluid"
              alt="logo"
              style={{ width: "340px", height: "120px" }}
            />
          </NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
              {this.state.tabs &&
                this.state.tabs.map((item) => (
                  <NavItem>
                    <Link
                      onClick={() => {
                        localStorage.setItem(
                          "dashboardNumber",
                          item.dashboardNumber
                        );
                        window.location = "/home";
                      }}
                      className="Link"
                      to="/home"
                    >
                      {item.name}
                    </Link>
                  </NavItem>
                ))}

              {this.state.user.user &&
                this.state.idRoleMap[this.state.user.user.role] ===
                  "SUPER_ADMIN" && (
                  <NavItem>
                    <Link className="Link" to="/tabs">
                      Tabs
                    </Link>
                  </NavItem>
                )}
              <NavItem>
                <Link className="Link" to="/resetPassword">
                  Reset Password
                </Link>
              </NavItem>
              <NavItem
                onClick={() => {
                  localStorage.removeItem("user");
                  localStorage.removeItem("dashboardNumber");
                }}
              >
                <Link className="Link" to="/login">
                  Logout
                </Link>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>

        {this.state.tabs && this.state.tabs.length > 0 ? (
          <div>
            <div
              dangerouslySetInnerHTML={{
                __html:
                  "<iframe src=" +
                  this.state.iFrameUrl +
                  ' frameborder="0" width="100%" height="800" allowtransparency></iframe>',
              }}
            />
          </div>
        ) : (
          <div>
            <p>Didn't have access to tabs</p>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default HomeComponent;
